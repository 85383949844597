
import React from "react";
import CountUp from "react-countup";

export default function Counter()  {
   
    return (<section className="text-center section-padding" id="counter">        
            <div className="container">
              
              <div className="row item-align-center row-cols-2 row-cols-sm-2 row-cols-md-4">
                <div className="col">    
                <h2><CountUp end={475} enableScrollSpy /></h2>
                    <h4>Mitglieder</h4>           
                </div>                 

                <div className="col">                   
                    <h2><CountUp end={9} enableScrollSpy /></h2>
                    <h4>Tennisplätze</h4>           
                </div>

                <div className="col">                   
                    <h2><CountUp end={2} enableScrollSpy /></h2>
                    <h4>Padelplätze</h4>           
                </div>

                <div className="col">                   
                    <h2><CountUp end={3} enableScrollSpy /></h2>
                    <h4>Hallenplätze</h4>           
                </div>
              </div>

            </div>
          </section>);
  
}